body,
label,
.checkbox label {
  font-weight: 500;
}
.skin-red .main-header li.user-header {
  background-color: #002d59 !important;
}
.skin-red .main-header .navbar {
  background-color: #222d32 !important;
}
.main-header .logo {
  padding: 0;
  line-height: normal;
}
.header {
  font-weight: bold;
  color: white !important;
}
.login-page,
.register-page {
  background: white;
}
.jqtree-element {
  min-height: 30px;
  /*padding: 3px;*/
  padding: 3px 0;
}
table.dataTable tbody tr.selected {
  background-color: #97BDD6 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
  background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}
.select2-container .select2-selection--single {
  height: 34px !important;
}
.document_node_label {
  display: inline-block;
  margin-left: 15px;
  text-align: left;
  vertical-align: middle;
}
.document_node_label > .node_attributes {
  display: inline-block;
}
.document_node_label > .node_attributes > * {
  vertical-align: middle;
}
/* typeahead */
/* scaffolding */
/* ----------- */
.tt-menu,
.gist {
  text-align: left;
}
.typeahead,
.tt-query,
.tt-hint {
  font-size: 20px;
  line-height: 30px;
  outline: none;
}
.typeahead {
  height: 44px;
  overflow: hidden;
  background-color: #fff;
}
.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: #999;
}
.tt-menu {
  max-width: 100%;
  margin: 0;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
  padding: 10px 10px;
  font-size: 18px;
  line-height: 24px;
}
.tt-suggestion > * {
  vertical-align: middle;
}
.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}
.tt-suggestion p {
  margin: 0;
}
.gist {
  font-size: 14px;
}
/* example specific styles */
/* ----------------------- */
#custom-templates .empty-message {
  padding: 5px 10px;
  text-align: center;
}
#scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}
#rtl-support .tt-menu {
  text-align: right;
}
.tt-highlight {
  font-weight: inherit;
  color: red;
}
/* LOADING OVERLAY */
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
}
.loading_inside {
  display: flex;
  margin: 0 auto;
}
.spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.skin-red .sidebar-menu > li.disabled:hover > a,
.skin-red .sidebar-menu > li.disabled > a {
  border-left-color: transparent !important;
  background: transparent !important;
  color: #b8c7ce !important;
  opacity: 0.4;
}
.nobr {
  white-space: nowrap;
}
.select2-selection__choice {
  background-color: #bce8f1 !important;
  color: darkslategray !important;
}
.select2-selection__choice__remove {
  font-size: 17px;
  padding: 1px;
  padding-right: 5px;
  color: darkslategray !important;
}
.select2-results__option {
  border-bottom: 1px solid #F2F2F2;
}
.dl-shortcodes dd {
  white-space: nowrap;
  border-bottom: 1px solid lightgray;
}
.copy-btn {
  padding: 0 5px;
}
/* APP FRAME */
.app-frame {
  background: #1D2A38 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 45px #00000033;
  border-radius: 50px;
  padding: 50px 40px 80px 40px;
  position: relative;
}
.app-frame::after {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 50px;
  height: 50px;
  background-color: #FFF;
  border-radius: 50%;
  margin-left: -25px;
  content: "";
  display: block;
}
.product-groups-labels {
  margin-top: 5px;
}
